import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import H2 from 'components/typography/h2'
import Button from 'components/button/button'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'

const FourZeroFourView = () => {
  const { heroImage } = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "404-header.jpg" }) {
        ...FullWidthImage
      }
    }
  `)
  return (
    <Layout>
      <Helmet>
        <title>Page Not Found | Hutson Inc</title>
      </Helmet>

      <HeroImage title='Page Not Found' image={heroImage} />

      <Content>
        <H2 textAlign='center'>We Dug Around But Couldn't Find It.</H2>
        <Message>
          Either the page you're looking for doesn't exist or we have to dig a little deeper. Let us
          know what you're looking for and we'll see if we can help you out.
        </Message>
        <Button as={StyledLink} to='/contact/'>
          Contact Us
        </Button>
      </Content>
    </Layout>
  )
}

const Message = styled.p`
  margin: 0 auto 30px;
  max-width: 600px;
  text-align: center;
`

const StyledLink = styled(Link)`
  display: block;
  margin: 0 auto;
  max-width: 200px;
  text-decoration: none;
  width: 100%;
`

export default FourZeroFourView
